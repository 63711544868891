<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2>Produto</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do produto</legend>
          </e-col>
          <e-col v-if="produto.id" class="flex items-center justify-end m-r" style="max-width: 220px; min-height: 32px">
            ID #{{produto.id}}
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 220px; min-height: 32px">
            <erp-s-field label="Visibilidade">
              <sl-checkbox yes-val="Auto Contratação" no-val="Interno" v-model="produto.active" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Nome">
                  <erp-input placeholder="Nome do produto" v-model="produto.nome" required autofocus/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Observações sobre o produto" v-model="produto.descricaoSimples" class="sl-textarea"
                            rows="8"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <collapse :collapse="false" title="Descrição completa sobre o Produto">
              <e-row mr>
                <e-col>
            <textarea placeholder="HTML é permitido" v-model="produto.descricaoCompleta" class="sl-textarea"
                      rows="18"/>
                </e-col>
              </e-row>
            </collapse>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Definições de preço</legend>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Critério de Cobrança:"
            >
              <erp-select v-model="produto.criterioCobranca" :options="criteriosCobranca" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Unidade de Cobrança:"
            >
              <erp-select v-model="produto.unidadeMedidaCobranca" :options="unidadeMedidaCobranca" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Quantidade Mínima:"
            >
              <erp-input size="2" simple-border v-model.lazy="produto.quantidadeMinima" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Forma de Pagamento:"
            >
              <erp-select v-model="produto.formaPagamento" :options="formasPagamento" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field
                label="Ativação:"
            >
              <erp-input size="2" v-money="money" simple-border v-model.lazy="produto.taxaInstalacao" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Custo:"
            >
              <erp-input size="2" v-money="money2" simple-border v-model.lazy="produto.custo" />
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Valor:"
            >
              <erp-input size="2" v-money="money2" simple-border v-model.lazy="produto.valor" />
              <div class="m-t-xs">
                <erp-s-field label="Lucro">
                  R$ {{lucro|moeda2}} <span class="bg-grey-4 font-10" style="padding: 2px 4px">{{lucroPct}}%</span>
                </erp-s-field>
              </div>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field
                label="Desconto Mensal:"
            >
              <erp-input size="2" v-money="percent" simple-border v-model.lazy="produto.descontoMensal" />
            </erp-s-field>
          </e-col>
        </e-row>
      </div>

      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Critérios de preço baseado no uso</legend>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <table class="full-width">
              <thead>
              <tr>
                <th class="text-left">Valor inicial</th>
                <th class="text-left">Valor final</th>
                <th class="text-left">Preço</th>
                <th class="text-right" style="max-width: 80px"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="criterio in produto.criterioUsos">
                <td class="text-left">
                  <erp-input size="2" type="number" simple-border v-model.lazy="criterio.limiteInferior" />
                </td>
                <td class="text-left">
                  <erp-input size="2" type="number" simple-border v-model.lazy="criterio.limiteSuperior" />
                </td>
                <td class="text-left"><erp-input size="2" v-money="money2" simple-border v-model.lazy="criterio.valorUnidade" /></td>
                <td class="text-right" style="max-width: 80px">
                  <a class="text-negative" @click="deleteCriterioUso(criterio)"><i class="fa fa-times"></i></a>
                </td>
              </tr>
              <tr v-if="!produto.criterioUsos || !produto.criterioUsos.length">
                <td colspan="100%"><div class="m-t">Nenhum critério de uso para este produto</div></td>
              </tr>
              </tbody>
            </table>
            <div class="m-t-sm text-right">
              <u-btn @click="addCriterioUso" label="Adicionar critério" icon="plus" icon-type="fa" color="blue" class="sl-btn" style="padding: 4px 14px" no-caps />
            </div>
          </e-col>
        </e-row>
      </div>

    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Projeto" no-caps
               :color="'green'"
               class="b-radius-3px sl-btn no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
import {newProduto, updateProduto, find} from "@/domain/gerencia/produtos/services"
import {converterParaFloat, formatarParaMoedaBrasileira, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
import SlCheckbox from "@/reuse/input/Checkbox"
import Collapse from "components/globalconfig/components/include/Collapse"
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"
import * as FormasPagamento from "@/domain/gerencia/produtos/helpers/formaPagamento"
import * as CriteriosCobranca from "@/domain/gerencia/produtos/helpers/criterioCobranca"

let mockProduto = {
  id: null,
  nome: null,
  descricaoSimples: null,
  descricaoCompleta: null,
  quantidadeMinima: null,
  unidadeMedidaCobranca: null,
  criterioCobranca: null,
  formaPagamento: null,
  taxaInstalacao: null,
  valor: null,
  descontoMensal: null,
  valorUso: null,
  criterioUsos: [],
  visibilidade: 0,
  configuracao: {
  },
  configuracaoString: null,
  active: true
}

const valores = ['valor', 'custo', 'descontoMensal']

export default {
  name: 'ProdutoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [GlobalPersonMixin],
  directives: {money: VMoney},
  components: {
    // ErpSelect,
    SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent,
    Collapse
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      percent: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 4,
        masked: false
      },
      money2: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 4,
        masked: false
      },
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      produto: JSON.parse(JSON.stringify(mockProduto))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    criteriosCobranca () {
      return Object.keys(CriteriosCobranca.CriteriosCobranca).map((key) => {
        let c = CriteriosCobranca.CriteriosCobranca[key]
        return {
          ...c,
          label: c.title,
          value: Number(key)
        }
      })
    },
    unidadeMedidaCobranca () {
      return ['unidade', 'hora', 'mensal'].map(v => {
        return {
          label: v.charAt(0).toUpperCase() + v.slice(1),
          value: v
        }
      })
    },
    formasPagamento () {
      return [
        {label: 'Opcional', value: null},
        {label: 'Pré-Pago', value: 0},
        {label: 'Pós-Pago', value: 1}
      ]
    },
    lucro () {
      const valor = converterParaFloat(this.produto.valor)
      const custo = converterParaFloat(this.produto.custo)
      if (isNaN(valor) || isNaN(custo) || valor === null || custo === null) {
        return 0
      }
      return parseFloat((parseFloat(valor) - parseFloat(custo)).toFixed(4))
    },
    lucroPct () {
      const valor = converterParaFloat(this.produto.valor)
      const custo = converterParaFloat(this.produto.custo)
      if (isNaN(valor) || isNaN(custo) || valor === null || custo === null || custo == 0) {
        return 0
      }
      const lucro = ((parseFloat(valor) - parseFloat(custo)) / parseFloat(custo)) * 100
      return parseFloat(lucro.toFixed(2))
    }
  },
  methods: {
    load() {
      this.loading = true
      find(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.produto.id = response.data.id
            }
            this.preventBind = true
            this.produto = Object.assign({}, this.produto, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.produto)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.produto))
      valores.map(key => {
        data[key] = converterParaFloat(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })
      data['taxaInstalacao'] = converterParaFloat(data['taxaInstalacao'])

      if (Array.isArray(data['criterioUsos']) && data['criterioUsos'].length) {
        data.criterioUsos = data.criterioUsos.map(c => {
          return {
            ...c,
            valorUnidade: converterParaFloat(c.valorUnidade)
          }
        })
      }

      /*const relacoes = ['pessoa', 'plano', 'servidor', 'gerente']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })*/

      /*const datas = ['mensalidadeProximoVencimento', 'contratoInicio']
      datas.map(key => {
        if (data[key]) {
          data[key] = datePtToEn(data[key])
        }
      })*/
      try {
        data.configuracao = JSON.stringify(data.configuracao)
      } catch (e) {
        alert('Configurações inválida')
        return
      }
      return data
    },
    prepareToEdit(data) {
      valores.map(key => {
        data[key] = formatarParaMoedaBrasileira(data[key], 4)
        if (!data[key]) {
          data[key] = null
        }
      })
      data['taxaInstalacao'] = formatarParaMoedaBrasileira(data['taxaInstalacao'], 2)
      /*const ids = ['plano', 'servidor']
      ids.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })*/
      /*const datas = ['mensalidadeProximoVencimento', 'contratoInicio']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })*/
      if (typeof data.configuracao !== 'object' || Array.isArray(data.configuracao)) {
        data.configuracao = {
          // instancia: null
        }
      }

      data.configuracaoString = JSON.stringify(data.configuracao, null, 4)
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newProduto
        if (this.produto.id) {
          method = (data) => updateProduto(data.id, data)
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.produto.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Produto com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Produto com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              if ((!this.$refs.uploader || (this.$refs.uploader && !this.$refs.uploader.existsFilesToUpload)) && (type === 'andClose' || (this.entidade && this.entidadeId))) {
                // this.$uloc.window.close(this.$root.wid)
              } else if (type === 'andNew') {
                this.preventBind = true
                this.produto = JSON.parse(JSON.stringify(mockProduto))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    },
    addCriterioUso () {
      this.produto.criterioUsos.push({
        id: null,
        limiteInferior: null,
        limiteSuperior: null,
        valorUnidade: null,
      })
    },
    deleteCriterioUso (criterio) {
      this.produto.criterioUsos.splice(this.produto.criterioUsos.indexOf(criterio), 1)
    }
  }
}
</script>
